html{margin:0; padding:0;   }

@font-face{font-family:VAG Rounded Std;
  src:url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-light-webfont.eot);
  src:url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-light-webfont.eot?#iefix) format("embedded-opentype"),
  url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-light-webfont.woff) format("woff"),
  url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-light-webfont.ttf) format("truetype"),
  url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-light-webfont.svg#vagroundedstdlight) format("svg");
  font-weight:400;font-style:normal;font-display:swap}
  
  @font-face{font-family:VAG Rounded Std;src:url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-bold-webfont.eot);
    src:url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-bold-webfont.eot?#iefix) format("embedded-opentype"),
    url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-bold-webfont.woff) format("woff"),
    url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-bold-webfont.ttf) format("truetype"),
    url(//www.in60seconds.nl/wp-content/themes/OneMinute/dist/styles/../fonts/vagroundedstd-bold-webfont.svg#vagroundedstdbold) format("svg");
    font-weight:700;font-style:normal;font-display:swap}


body {
  background-color:#eee;      
  background-color: #f1f0f2;
  font-family: Abel; 
  font-weight: 400; 
  overflow: hidden;
}

h1 {text-align: center; margin-bottom: 0; margin-top: 10px; min-height: 45px;}
h2{
  box-sizing: border-box;
  margin: 0;
  padding: 0px 0px;
  height: 30px;
  font-size: 18px;
  text-align: center;
  color:#2c424d;
  font-weight: 400;; 
  min-height: 30px;
}

.logo {width: 100px; position:absolute; left: 20px; top: 20px;}

#loginstatus { white-space: nowrap; position:absolute; right: 20px; top: 15px; text-align: right; color: #999;}




.expo { 
  padding: 0 10% 40px 10% ;
  // font-family:VAG Rounded Std;

}



.text {
  padding-top: 20px;
  opacity: 0;
  transition: opacity .5s;
  &.filled{
    opacity: 1;
  }

  .header{font-weight: bold; font-size: 18px; padding-bottom:0px;}
 .description {font-size: 18px;}
}



.live {width: 100%;
  // height: 0;
  // padding-bottom: calc(56.25% + 40px);


// height:calc( (100vw - 160px) *0.5625);
//   max-height: calc( (100vw - 160px) *0.5625);

  /* padding-bottom: 56.25%; */
  // max-height: 1080px;
  // max-width: 1920px;
 
  // max-width: 1920px;  
  margin: 0 auto;
  opacity: 0.2;
  transition: opacity 1s;  
  transition-delay: .5s;  
  background-color: #d8e4eb;

  transform: scale(1);
  transform-origin: top;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  
  box-shadow:rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px, rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.wswrapper{
  transform-origin: top left;
  // transform:scale(0.5);
  position: relative;
  // width: 1920px;
  // height: 1080px;
  // overflow: hidden;
  opacity: 0;
  transition: opacity 1s;  
  transition-delay: .5s;

}

.buttons {
  position: relative;
  bottom: 1px;
  z-index: 99;
  margin-top: 0;
  width: 100%;
  height: 35px;

  pointer-events: all;
  transition-delay: 0s;
  transition: all .5s;
  transform: translateY(0) translateZ(0);
  box-sizing: border-box;
  background-color: #eee;



    .innerbuttons {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25px, auto));
      justify-content: center;
      justify-self: center;
      align-items: start;
      justify-items: center;
      transform: scale(0.3) translateZ(0);
      transition: transform .5s;
      transition-delay: 0s;
      transform-origin: center 6.66%;
      position: relative;
      width: 100%;
      top:2.5px;

    }

    .optionbuttons{
      position: absolute;
      bottom: 0;
      right: -85px;
      margin:0;
      display: grid;
      grid-template-columns: auto;
      grid-gap: 15px;
      padding: 30px 30px 0 30px;
      top:unset;

      .buttonwrap {
        margin: 0;
      }
      button {
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        margin: 0;
      }
    }


    .buttonwrap {
      
     position: relative;
      top: 0px;
      display: inline-block;
      border-radius: 10px;;
      margin: 7.5px 0;
      &:nth-child(9){transition-duration: 0.1s;  transition-delay: 1s;}
      &:nth-child(8){transition-duration: 0.2s;  transition-delay: 1s;}
      &:nth-child(7){transition-duration: 0.3s;  transition-delay: 1s;}
      &:nth-child(6){transition-duration: 0.4s;  transition-delay: 1s;}
      &:nth-child(5){transition-duration: 0.5s;  transition-delay: 1s;}
      &:nth-child(4){transition-duration: 0.5s;  transition-delay: 1s;}
      &:nth-child(3){transition-duration: 0.6s;  transition-delay: 1s;}
      &:nth-child(2){transition-duration: 0.7s;  transition-delay: 1s;}
      &:nth-child(1){transition-duration: 0.8s;  transition-delay: 1s;}
     
      pointer-events: all;
      border:none;
      background-color: rgba(0,0,0,0);
    
      &.slide{
        cursor: move;
        &:hover {
          background-color:white
        }
    
      }
    }

    &:hover{
      background-color: #eee;
      height: 90px;
      margin-top: -55px;
       box-shadow: rgba(17, 17, 26, 0.02) 0px -4px 16px, rgba(17, 17, 26, 0.02) 0px -8px 24px;

      .innerbuttons{
        transform: scale(1) translateY(-0px);
        transition-delay: 0s;
      }

      .innerbuttons button {
        color:rgba(0,0,0,1);
        margin:  12.5px 12.5px;
        &.active{
          color:#d8e4eb;
        }
      }

      .buttonwrap{
        top: 0;
        transition-delay: 0s;
        &:nth-child(1){transition-duration: 0.3s;}
        &:nth-child(2){transition-duration:  0.35s}
        &:nth-child(3){transition-duration:  0.4s}
        &:nth-child(4){transition-duration:  0.45s}
        &:nth-child(5){transition-duration:  0.5s}
        &:nth-child(6){transition-duration:  0.55s}
        &:nth-child(7){transition-duration:  0.6s}
        &:nth-child(8){transition-duration:  0.65s}
        &:nth-child(9){transition-duration:  0.7s}
       
    
      }
    }


  }
  
 
  



.expo button {
    padding: 5px;
    font-size: 10px;
    font-weight: 700;
    min-width: 40px;
    min-height: 40px;
    background-color: #CFD2D4;
    border: none;
    margin:  12.5px 7.5px;
   
    border-radius: 100px;
    transition: all 0.3s, transform .8s;
    cursor: pointer;
    pointer-events: all;
    transition-timing-function: cubic-bezier(.42,0,.23,.99);
    box-shadow: 0 0 0 3px #d8e4eb;   
    color:rgba(0,0,0,0);

    &.option{
      min-width: 30px;
      min-height: 30px;
      font-size: 20px;
      line-height: 0;;
      position: relative;
      background-color: #eee;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
      margin: 0;
     // box-shadow: 0 0 0 3px #999696;
     &:hover {
      background-color:white
     }

     img {
       width: 12px;
       height: auto;
     }

    }




    &:focus {
      outline: none;
    }
    &.active {
      background-color: #e20a7d;
      color:rgba(250, 250, 250, 0);
      box-shadow: 0 2px 0 #b10862;
      transform: scale(1.1);
      transition-delay: 0s;
      &.latest{
        opacity: 0.4;
      }
    }
    &:hover{
      opacity: 0.9;
    }


    &.tooltip .tooltiptext {
      visibility: hidden;
      width: 60px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 14px 0;
      position: absolute;
      font-size: 10px;
      z-index: 1;
      top: 50%;
      margin-top: -14px;
      right: -70px;
      margin-left: 0px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    &.tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: -5px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #555 transparent transparent;
    }
    
    &.tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }

    &:not(.tooltip) .tooltiptext {display: none;}


  }





.workspace {
  // width: calc(1920px *2);
  // height: calc(1080px *2);
  width: 100%;
  height:100%;
  // transform: scale(.5);
  margin: 0 auto;
  background-color: #2c424d;
  transform-origin: top left;

  .layers {position: relative; height: 100%; transform-origin: top left;}
  .layer {position:absolute; top:0;
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden; 
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
  
    &.loading{ width: 100%; height: 100%; background-color: white;}
  }


  .panzoom {height: 100%;}


  .welcome {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    font-size: 120px;
    transform: translate(-50%, -50%);
  }
}



// #layer2{display:none}
.optionbuttons{
top: 0;
    width: 30px;
    position: absolute;
    bottom: 0;
    right: -85px;
    margin: 0;
    display: grid;
    grid-template-columns: auto;
    grid-gap: 15px;
    padding: 0px 30px 0 30px;
    align-content: flex-start;

    &.bottom{
      bottom:0;
      top:unset;
    }
}



.navigationbuttons{
  position: absolute;
    top: calc(50% - 50px);
    width: 100%;

    button.navigation {
      width: 40px;
      background: none;
      border: none;
      position: absolute;
      right: -70px;
      outline: none;
      opacity: 0.1;
      border: none;
      box-shadow: none;
      transition: opacity .3s;
      


      &.nav-left{
        left: -70px;
      }
      &:hover{
        opacity: 0.2;
        cursor: pointer;
      }

      img{
    
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))
      }
  }

}

.scale-adjusted .wswrapper{ opacity: 1;}
.scale-adjusted .live{ opacity: 1;}

.fullscreenmode .buttons{position: absolute; bottom: 0;}
.fullscreenmode .workspace{    position: absolute;  top: 50%; left:50%;  transform: translate(-50%,-50%);}

.withlogin {display:none}
.loggedin .withlogin {display: block}
.loggedin .withlogin.inline {display: inline-block}


.modal {
  @import 'components/modal';
  
  .editinputwrap{
    input, textarea {
      width: 100%;
      font-size: 14px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      padding: 5px;
    }
    input{margin-bottom: 10px;}
  }

  #modal-login .modal__container {max-width: 400px;}
}